export const portfolioData = [
  {
    id: 1,
    name: 'Top-enr.com - simulateur d\'aide',
    languages: ['javascript','css','PHP', 'Symfony'],
    languagesIcons: ['fab fa-js','fab fa-css3-alt','fab fa-php', 'fab fa-symfony'],
    source: 'https://top-enr.com/simulateur-daide',
    info: 'Création et intégration d\'un simulateur d\'aide aux aides de l\'Etat ( MaPrimeRenov\', Coup de pouce chauffage, Coup de pouce energie )',
    picture: './media/top-enr-simulateur_aide.JPG'
  },
  {
    id: 2,
    name: 'CV web',
    languages: ['css','javascript','react'],
    languagesIcons: ['fab fa-css3-alt', 'fab fa-js','fab fa-react'],
    source: 'https://www.anthony-romian-dev.fr/CV',
    info: 'Réalisation de mon cv avec React',
    picture: './media/project2.JPG'
  },
  {
    id: 3,
    name: 'Délices grecs',
    languages: ['wordpress','javascript','php','css'],
    languagesIcons: ['fab fa-wordpress','fab fa-js','fab fa-php','fab fa-css3-alt'],
    source: 'https://www.delices-grecs.fr',
    info: 'Création de site internet pour sandwicherie, mise en place d\'un click & collect afin de gerer les commandes à emporter',
    picture: './media/project3.JPG'
  },
  
  {
    id: 4,
    name: 'Quran planner',
    languages: ['javascript','css','php', 'Symfony'],
    languagesIcons: ['fab fa-js','fab fa-css3-alt','fab fa-php', 'fab fa-symfony'],
    source: 'http://www.github.com',
    info: 'Générateur de plannification de révision',
    picture: './media/project6.JPG'
  },
]