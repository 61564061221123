import React from 'react';

const Experiences = () => {
    return (
        <div className="experience">
            <h3>Expérience</h3>
            <div className="exp-1">
                <h4>Developpeur PHP Symfony</h4>
                <h5>Janvier 2022 à aujourd'hui</h5>
                <p><span>Auto-entrepreneur</span> | Réalisation de site internet selon besoin client <br></br></p>
                <ul>
                    <li className="exp-list">Recueil du besoin client</li>
                    <li className="exp-list">Site Symfony | Wordpress</li>
                    <li className="exp-list">Gestion de la maintenance</li>
                </ul>
            </div>
            <div className="exp-2">
                <h4>Developpeur PHP Symfony</h4>
                <h5>Sep-Oct 2021</h5>
                <p><span>TOP-EnR</span> | Simulateur d'aides de l'Etat<br></br>
                Analyse, La conception et développement d’un simulateur d'aide de
                l'Etat | MaprimeRenov', Coup de pouce énergie, coup de pouce
                chauffage</p>
                <ul>
                    <li className="exp-list">Utilisation de PHP avec framework Symfony 5 et MySQL</li>
                    <li className="exp-list">Utilisation de Bootstrap / Javascript</li>
                    <li className="exp-list">Intégration sur Wordpress existant</li>
                </ul>
            </div>
            <div className="exp-3">
                <h4>Developpeur PHP Symfony</h4>
                <h5>Juil 2021</h5>
                <p><span>ENI ECOLE</span> | Projet site internet d'organisation de sorties entre élèves<br></br>
                Création et gestion de sorties entre élèves
                </p>
                <ul>
                    <li className="exp-list">Utilisation de PHP avec Symfony et MySQL</li>
                </ul>
            </div>
            <div className="exp-4">
                <h4>Developpeur JAVA </h4>
                <h5>Mai 2021</h5>
                <p><span>ENI ECOLE</span> | Projet site internet de vente aux enchères<br></br>
                Création et gestion de vente aux enchères
                </p>
                <ul>
                    <li className="exp-list">Utilisation de Java EE et SQL Server</li>
                </ul>
            </div>
            <div className="exp-5">
            <h4>Intégrateur électrique EHI-EHA</h4>
                <h5>2012 – 2018</h5>
                <p><span>SAFRAN</span> | A350 & Projet Fusio
                sur Boeing 777 <br></br>
                Étude de routage harnais électrique (EHI EHA, Sheet Metal Design )
                </p>
                <ul>
                    <li className="exp-list">Connaissance des normes  & environnement <span>AIRBUS</span></li>
                    <li className="exp-list">Réalisation de plan Full3D.</li>
                    <li className="exp-list">Collaboration entre différents services</li>
                </ul>
            </div>
            <div className="exp-6">
                <h4>Agent de production</h4>
                <h5>2003 – 2012</h5>
                <p><span>FRESSCALE</span> | <span>CONTINENTAL</span> | <span>STMICROELECTRONICS</span> | <span>SKF</span> | <span>SANDVIK</span><br></br></p>
                <ul>
                    <li className="exp-list">Conduite de ligne d'assemblage, maintenance et réglage machine outil</li>
                </ul>
            </div>
        </div>
    );
};

export default Experiences;