import React from 'react';

const OtherSkills = () => {
    return (
        <div className="otherSkills">
            <h3>Autres compétences</h3>
            <div className="list">
                <ul>
                    <li><i className="fas fa-check-square"></i>
                    Anglais B2</li>
                    <li><i className="fas fa-check-square"></i>
                    Github</li>
                    <li><i className="fas fa-check-square"></i>
                    SEO</li>
                    <li><i className="fas fa-check-square"></i>
                    Méthodes agiles</li>
                </ul>
                <ul>
                    <li><i className="fas fa-check-square"></i>
                    Figma</li>
                    <li><i className="fas fa-check-square"></i>
                    Design</li>
                    <li><i className="fas fa-check-square"></i>
                    Photoshop</li>
                    <li><i className="fas fa-check-square"></i>
                    Montage Vidéo</li>
                </ul>
            </div>  
        </div>
    );
};

export default OtherSkills;