import React from 'react';

const ProgressBarAero = (props) => {

    return (
        <div className={props.className}>
            <h3>{props.title}</h3>
            <div className="years">
                <span>Années d'experience</span>
                <span>5 ans</span>
                <span>10 ans</span>
            </div>

            <div>
                {
                    props.languages.map((item)=>{

                        let xpYears = 2;
                        let ProgressBarAero = item.xp / xpYears * 100 +'%';
                   
                        return (
                            <div key={item.id} className="languagesList">
                                <li>{item.value}</li>
                                <div className="progressBar" style={
                                    {width:ProgressBarAero}}></div>
                            </div>

                        )
                    })
                }

            </div>
        </div>
    );
};

export default ProgressBarAero;