import React from 'react';
import Navigation from '../components/Navigation';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const contact = () => {
    return (
        <div className="contact">
            <Navigation />
            <div className="contactContent">
                <div className="header"></div>
                    <div className="contactBox">
                        <h1>Contactez-moi</h1>
                        <ul>
                            <li>
                                <i className="fas fa-map-marker-alt"></i>
                                <span>Toulouse</span>
                            </li>
                            <li>
                                <i className="fas fa-mobile-alt"></i>
                                <CopyToClipboard text="0782165629">
                                <span className="clickInput" onClick={( )=>
                                {alert('Téléphone copié!');}}>
                                07 82 16 56 29</span>
                                </CopyToClipboard>
                            </li>
                            <li>
                                <i className="fas fa-envelope"></i>
                                <CopyToClipboard text="aromian1@gmail.com">
                                    <span className="clickInput" onClick={( )=>
                                    {alert('Email copié!');}}>
                                    aromian1@gmail.com</span>
                                </CopyToClipboard>
                            </li>
                        </ul>
                    </div>
                <div className="socialNetwork">
                    <ul>
                        <a href="https://www.linkedin.com/in/anthony-romian-9535a3148" target="_blank"
                        rel="noopener noreferrer">
                            <h4>LinkedIn</h4>
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a href="https://github.com/AnthonyRomian" target="_blank"
                        rel="noopener noreferrer">
                            <h4>Github</h4>
                            <i className="fab fa-github"></i>
                        </a>
                    </ul>
                </div>   
            </div>
            
        </div>
    );
};

export default contact;