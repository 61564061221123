import React, { Component } from 'react';
import ProgressBar from './ProgressBar';
import ProgressBarAero from './ProgressBarAero';

class Languages extends Component {
    state = {
        languages:[
            {id:1, value: "PHP", xp:1.5},
            {id:2, value: "Javascript", xp:1.5},
            {id:4, value: "HTML", xp:2},
            {id:5, value: "Css", xp:2},
            ],
        frameworks:[
            {id:1, value: "Symfony", xp:1.5},
            {id:2, value: "React", xp:0.4},
            {id:3, value: "Sass", xp:1.5},
            {id:4, value: "Boostrap", xp:1.5},
            {id:5, value: "Tailwind", xp:1.5},

        ],
        logiciel3d:[
            {id:1, value: "Catia V5", xp:1.8},
            {id:2, value: "PLM - Windchill / pdmlink", xp:1.8},
            {id:3, value: "Photoshop", xp:0.5}
        ]
    }
    
    
    render() {
        let {languages, frameworks,logiciel3d} = this.state;
        return (
            <div className="languagesFrameworks">
                <ProgressBar
                languages={languages}
                className="languagesDisplay"
                title ="languages"
                />
                <ProgressBar 
                languages={frameworks} 
                title="frameworks & bibliothèques"
                className="frameworksDisplay"
                />
                <ProgressBarAero 
                languages={logiciel3d} 
                title="Logiciels divers"
                className="logicielDisplay"
                />
            </div>
        );
    }
}

export default Languages;