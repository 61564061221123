import React from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import Home from './pages/Home';
import Knowledges from './pages/Knowledges';
import Portfolio from './pages/Portfolio';
import contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Formation from './pages/Formation';

const App = () => {
  return (
    <>
      <BrowserRouter>
      <Switch>
      <Route path="/" exact component={Home}/>
      <Route path="/formation" component={Formation}/>
      <Route path="/competences" component={Knowledges}/>
      <Route path="/portfolio" component={Portfolio}/>
      <Route path="/contact" component={contact}/>
      <Route  component={NotFound}/>
      </Switch>
      </BrowserRouter>
      
    </>
  );
};

export default App;