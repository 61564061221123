import React from 'react';

const Hobbies = () => {
    return (
       <div className="hobbies">
            <h3>Intérêts</h3>
            <ul>
                <li className="hobby">
                    <i className="fas fa-running"></i>
                    <span>Course à pied</span>
                </li>
                <li className="hobby">
                    <i className="fas fa-futbol"></i>
                    <span>Jiu jitsu, Football</span>
                </li>
                <li className="hobby">
                    <i className="fas fa-book"></i>
                    <span>Lecture</span>
                </li>
                <li className="hobby">
                    <i className="fas fa-globe-europe"></i>
                    <span>Langues étrangères</span>
                </li>
            </ul>
       </div>
    );
};

export default Hobbies;