import React from 'react';
import Navigation from '../components/Navigation';

const Home = () => {
    return (
        <div className="home">
            <Navigation />
            <div className="homeContent">
                <div className="content">
                    <h1>Anthony ROMIAN</h1>
                    <h2>Developpeur Front-End</h2>
                    <div className="pdf">
                        <a href=".\media/CV_ROMIAN.pdf" download target="blank">Télécharger CV</a>
                    </div>


                </div>


            </div>
        </div>
    );
};

export default Home;