import React from 'react';
import Navigation from '../components/Navigation';

const Formation = () => {
    return (
        <div className="formation">
            <Navigation />   
            <div className="formationContent">
                <div className="compInfo">
                    <h2 className="header">Competences informatiques</h2>
                    <div className="competences">
                        <div className="languagesetFrameworks">
                        <h4 className="tilte">Languages et Framework</h4>
                        <ul>
                            <li>PHP 7</li>
                            <li>Symfony 5</li>
                            <li>HTML 5 & CSS</li>
                            <li>Twig</li>
                            <li>Bootstrap | Tailwind</li>
                            <li>Javascript</li>
                            <li>SQL, PL/SQL</li>
                        </ul>
                        </div>
                        <div className="basesDonnees">
                        <h4 className="tilte">Bases de données</h4>
                        <ul>
                            <li>SQL Server</li>
                            <li>MySQL</li>
                            <li>Firebase</li>
                        </ul>  
                        <h4 className="tilte">ORM</h4>
                        <ul>
                            <li>Doctrine</li>
                        </ul>        
                        </div>
                        <div className="competenceTransverse">
                        <h4 className="tilte">Compétences transverses</h4>
                        <ul>
                            <li>PHP Storm</li>    
                            <li>Web Storm</li>    
                            <li>Agile (Scrum)</li>  
                            <li>UML</li>
                        </ul>
                    </div> 
        
                    <div className="logicielModelisation">
                        <h4 className="tilte">Logiciel de modélisation</h4>
                        <ul>
                            <li>Photoshop</li>
                            <li>CATIA V5</li>
                            <li>Autocad</li>
                            <li>Solidworks</li>
                        </ul>
                    </div> 
                    <div className="plm">
                        <h4 className="tilte">PLM / Depot distant</h4>
                        <ul>
                            <li>Github</li>
                            <li>Enovia VPM</li>
                            <li>Smarteam</li>
                        </ul>
                    </div> 
                    </div> 
                    
                </div>         
                <div className="formationSco">
                            <div className="form-1">
                                <h2 className="sectionTitle">Formation</h2>
                                <h4>FORMATION DEVELOPEUR WEB ET WEB MOBILE | ENI ECOLE INFORMATIQUE</h4>
                                <h5>2020 - 2021</h5>
                                <p>Développement d’un site internet de ventes aux enchères et
                                d’échanges de biens avec Java EE et SQL Server : application web
                                organisée en couches.<br></br>
                                Analyse, La conception et développement d’un site internet
                                d’organisation de sorties entre élèves avec PHP avec Symfony et
                                MySQL.<br></br>
                                </p>
                            </div>
                            <div className="form-2">
                                <h4>Formation anglais - Pratique orale de l'anglais</h4>
                                <h5>2015</h5>
                                <h4>Formation EHI-EHA & Sheet Metal Design | Pdmlink SSCI, VPM</h4>
                                <h5>2012</h5>
                                <h4>Formation Greta Toulouse - CATIA V5 150h</h4>
                                <h5>2010</h5>
                                <h4>BTS Supérieur Technicien Conception Industriel - AFPA LYON (69)</h4>
                                <h5>2008-2009</h5>
                                <h4>BAC Technologique STI Génie microtechnique - Tours (37)</h4>
                                <h5>2002</h5>
                            </div>
                        </div>
            </div>
        </div>
    );
};

export default Formation;